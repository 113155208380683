<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        class="mb-3"
      >
        <b-row align-h="center">
          <b-img
            v-if="inpoData.cover"
            :src="`${$entitiesImagesURL}/${inpoData.cover}`"
            width="1000"
            thumbnail
          />
          <b-img
            :src="require('@/assets/images/default-entities/Default_inpo_cover.png')"
            width="1000"
            thumbnail
          />
        </b-row>
      </b-col>
      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            v-if="inpoData.logo"
            :src="`${$entitiesImagesURL}/${inpoData.logo}`"
            :text="avatarText(inpoData.name)"
            size="104px"
            rounded
          />
          <b-avatar
            v-else
            :src="require('@/assets/images/default-entities/Default_inpo_logo.png')"
            :text="avatarText(inpoData.name)"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ inpoData.name }}
              </h4>
              <span class="card-text">{{ inpoData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'inpo-edit', params: { id: $router.currentRoute.params.id} }"
                variant="primary"
              >
                Edit
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <b-row class="d-flex align-items-center mt-3">
          <b-col cols="12">
            <h2>About</h2>
          </b-col>
          <b-col>
            <p>
              {{ inpoData.about }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Street</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ inpoData.address[0].street }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">City</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ inpoData.address[0].city }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">State</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ inpoData.address[0].state }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country</span>
            </th>
            <td class="pb-50">
              {{ inpoData.address[0].country }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Contact</span>
            </th>
            <td>
              {{ inpoData.phone_number }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <back />
  </b-card>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import getInpo from '@/common/compositions/Inpos/getInpo'
import Back from '@/common/components/common/Back.vue'

export default {
  components: { Back },
  setup() {
    const { inpoData } = getInpo()
    return {
      avatarText,
      inpoData,
    }
  },
}
</script>

<style>

</style>
